import type { NextPage } from "next";
import { ErrorPage } from "../components/pages/ErrorPage/ErrorPage";

const Page: NextPage = () => {
  return (
    <ErrorPage
      messages={{
        meta: "errors:404.meta",
        title: "errors:404.title",
        code: "errors:404.code",
        back: "errors:404.back",
      }}
    />
  );
};

export default Page;
